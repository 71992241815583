import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

const ContactSection = () => {
  const refForm = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();

    const email = refForm.current.correo.value;

    // Validación del correo
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Swal.fire({
        title: '¡Cuidado!',
        text: 'Correo incorrécto.',
        icon: 'warning',
        confirmButtonColor: '#94c11f',
        confirmButtonText: 'Aceptar'
      });
      return; // Detener la ejecución si el correo es inválido
    }

    const serviceId = 'service_jei5o0n';
    const templateId = 'template_yqxxohd';
    const apikey = 'vX7btMImpF8GJ8NZ1';

    const templateParams = {
      name: refForm.current.name.value,
      correo: refForm.current.correo.value,
      empresa: refForm.current.empresa.value,
      asunto: refForm.current.asunto.value,
      mensaje: refForm.current.mensaje.value
    };

    const templateIdUser = 'template_cju1tfi';
    const templateParamsUser = {
      name: refForm.current.name.value,
      correo: refForm.current.correo.value
    };

    console.log(templateParams)
    emailjs.send(serviceId, templateId, templateParams, apikey)
      .then(result => console.log(result.text))
      .catch(error => console.error(error));

    emailjs.send(serviceId, templateIdUser, templateParamsUser, apikey)
      .then(result => {
        console.log(result.text)
        Swal.fire({
          title: '¡Éxito!',
          text: 'El formulario se envió correctamente.',
          icon: 'success',
          confirmButtonColor: '#94c11f',
          confirmButtonText: 'Aceptar'
        });
        refForm.current.reset()
      })
      .catch(error => console.error(error));
  }


  return (
    <section className="py-16 bg-gray-100" id='contacto'>
      <div className="max-w-3xl mx-auto px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-10">Contacto</h2>
        <form ref={refForm} onSubmit={handleSubmit} className="space-y-6">
          <div>
            <input
              className="w-full p-4 border border-gray-300 rounded-md"
              type="text"
              name="name"
              id="name"
              placeholder="Nombre"
              required
            />
          </div>
          <div>
            <input
              className="w-full p-4 border border-gray-300 rounded-md"
              type="text"
              name="correo"
              id="correo"
              placeholder="Correo"
              required
            />
          </div>
          <div>
            <input
              className="w-full p-4 border border-gray-300 rounded-md"
              type="text"
              name="empresa"
              id="empresa"
              placeholder="Empresa"
              required
            />
          </div>
          <div>
            <input
              className="w-full p-4 border border-gray-300 rounded-md"
              type="text"
              name="asunto"
              id="asunto"
              placeholder="Asunto"
              required
            />
          </div>
          <div>
            <textarea
              className="w-full p-4 border border-gray-300 rounded-md h-32"
              name="mensaje"
              id="mensaje"
              placeholder="Tu mensaje..."
              maxLength="5000"
              required
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full bg-black text-white py-4 rounded-md font-bold"
            >
              Enviar Mensaje
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactSection;
